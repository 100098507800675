
@font-face {
  font-family: "black";
  src: url('../fonts/black.woff');
}
@font-face {
  font-family: "bold";
  src: url('../fonts/bold.woff');
}
@font-face {
  font-family: "Lato-Light";
  src: url('../fonts/Lato-Light.ttf');
}
@font-face {
  font-family: "Lato-Regular";
  src: url('../fonts/Lato-Regular.ttf');
}
